import { Table, Popup, Icon } from "semantic-ui-react";

import "./CommonTable.scss";

export const CommonTable = ({
  tableHeaderData = [],
  children,
  singleLine = true,
  isSimulateView,
}: any) => {
  return singleLine ? (
    <Table singleLine={singleLine}>
      <Table.Header>
        <Table.Row>
          {tableHeaderData.map((data: any, index: any) => {
            return (
              <Table.HeaderCell
                className={isSimulateView ? "tbleHeaderSimulate" : "tbleHeader"}
                width={data?.col}
                key={index}
              >
                {data?.name || ""}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>{children}</Table.Body>
    </Table>
  ) : (
    <Table celled structured>
      <Table.Header>
        <Table.Row>
          {tableHeaderData.map((data: any, index: any) => {
            return (
              <Table.HeaderCell
                className={isSimulateView ? "tbleHeaderSimulate" : "tbleHeader"}
                width={data?.col}
                key={index}
              >
                <span>{data?.name || ""}</span>
                {data?.information ? (
                  <Popup
                    content={data?.information}
                    trigger={
                      <Icon className="iconPM" name="exclamation circle" />
                    }
                  />
                ) : null}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>{children}</Table.Body>
    </Table>
  );
};
