export default {
  //common
  mainColor: "#11387F",
  mainTextColor:"#11387F",
  mainTextInputHeader:"#007FC8",
  mainTextInptColor:"#11387F",
  mainTextInputBorderColor:"#93C7C6",
  //menu bar
  menuBarIconActive: "#11387F",
  menuBarIconDeactive: "#93C7C6",
  //table
  tableHeaderColor: "#007FC8",
  //product summary
  pecentageSub:"#93C7C6",
  //product summary header
  productSummaryTab:"#007FC8",
  activeProductTab:"#ffffff",
  cardHeader:"#ffffff",
  nodeCardColor:"#ECE8E5",
  cardIconColor:"#11387F",
  digramSilder:"#ECE8E5",
  drawerSearchView:"#D7D2CB",
  drawingIconsSepia: "100%",
  drawingIconsHueRotate: "150deg",
};
